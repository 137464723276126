<template>
    <section class="pt-3">
        <div class="row mx-0 align-items-center">
            <div class="col-4 px-0">
                <div class="row mx-0 align-items-end">
                    <div class="col">
                        <p class="f-12 text-general pl-3">Selecciona rango de fecha</p>
                        <el-date-picker
                        v-model="fechas"
                        class="w-100"
                        size="small"
                        type="daterange"
                        :clearable="false"
                        format="dd-MMM-yyyy"
                        value-format="yyyy-MM-dd"
                        placeholder="Pick a day"
                        />
                    </div>
                    <div class="col-auto px-2">
                        <div class="btn-general text-white f-14 px-4" @click="getData">
                            Buscar
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row mx-0 bg-white br-8 py-1">
                    <div class="col-3 text-general border-right">
                        <b class="f-30">
                            {{cantDeliverys}}
                        </b>
                        <span class="f-18 ml-3">Deliverys</span>
                    </div>
                    <div class="col-3 text-general border-right">
                        <b class="f-30">
                            {{cantEntregas}}
                        </b>
                        <span class="f-18 ml-3">Entregas</span>
                    </div>
                    <div class="col text-general">
                        <b class="f-30">
                            {{separadorNumero(totalDinero)}}
                        </b>
                        <span class="f-18 ml-3">Recibido</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12 px-0 py-3 text-general f-18 f-500 bg-white br-t-8 pl-5">
                Deliverys con entregas en estas fechas
            </div>
            <div class="col-12 px-0 bg-white br-b-12">
                <el-table :data="tableData" header-row-class-name="text-general f-16" style="width: 100%">
                    <el-table-column fixed prop="nombre" class-name="text-general" label="Nombre" min-width="180" />
                    <el-table-column prop="pedidos" class-name="text-general text-center" label="Pedidos" min-width="90" />
                    <el-table-column prop="peso" class-name="text-general text-center" label="Peso (Kg)" min-width="90" />
                    <el-table-column prop="volumen" class-name="text-general text-center" label="Peso Volumetrico (m3)" min-width="120" />
                    <el-table-column class-name="text-center text-general" label="Dinero en efectivo" min-width="150">
                        <template slot-scope="{row}">
                            <p class="text-center">
                                {{ separadorNumero(row.dinero_efectivo) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column class-name="text-center text-general" label="Dinero en transferencia" min-width="150">
                        <template slot-scope="{row}">
                            <p class="text-center">
                                {{ separadorNumero(row.dinero_transferencia) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="distancia" class-name="text-general text-center" label="Distancia (Km)" width="120" />
                    <el-table-column label="Calificación" class-name="text-center" min-width="120">
                        <template slot-scope="{row}">
                            <p>
                                {{ agregarSeparadoresNumero(row.calificacion,1) }}
                                <i class="icon-star f-20 text-warning" />
                            </p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </section>
</template>

<script>
import Entregas from "~/services/delivery/index";
export default {
    data(){
        return {
            fechas: [],
            tableData: [],
            cantDeliverys:0,
            cantEntregas:0,
            totalDinero:0
        }
    },
    methods: {
        async getData(){
            try {
                if(this.fechas.length == 0) return
                const params = {
                    inicio:this.fechas[0],
                    fin:this.fechas[1]
                }
                const {data} = await Entregas.getTablaComparativa(params)
                this.tableData = data.tabla
                this.cantDeliverys = data.cant_deliverys
                this.cantEntregas = data.cant_entregas
                this.totalDinero = data.total_dinero

            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>
